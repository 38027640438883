import useOneLinkUrl from "hooks/useOneLinkUrl";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useWindowWidth } from "@react-hook/window-size";
import { checkCookies, setCookies } from "cookies-next";
import { useEffect, useState } from "react";
import { Trans } from "@lingui/macro";
import { useRouter } from "next/router";

const cookieKey = "downloadAppBanner";

const DownloadAppBanner = () => {
  const oneLink = useOneLinkUrl("https://link.luminews.my/ewC9/dlappbanner");
  const hasOpen = checkCookies(cookieKey);
  const width = useWindowWidth();
  const [showBanner, setShowBanner] = useState(false);
  const router = useRouter();
  const { query } = router;

  // useEffect(() => {
  //   setShowBanner(width <= 640 && !hasOpen);
  // }, [width, query]);

  useEffect(() => {
    if (!router.isReady) {
      setShowBanner(false);
    } else if (query.m === "true") {
      setShowBanner(false);
    } else {
      setShowBanner(width <= 640 && !hasOpen);
    }
  }, [router.isReady]);

  // const hideBanner = (isXClicked) => {
  //   const today = new Date();
  //   const resetDate = new Date(today.setDate(today.getDate() + 30));
  //   setCookies(cookieKey, true, { expires: resetDate });
  //   setShowBanner(false);
  // };

  /* SHOW FOR GE15 */
  const hideBanner = (isXClicked) => {
    const inAnHour = new Date(new Date().getTime() + 60 * 60 * 1000);
    setCookies(cookieKey, true, { expires: inAnHour });
    setShowBanner(false);
  };

  if (showBanner) {
    return (
      <div
        className="fixed inset-x-0 bottom-0 z-30 flex justify-between bg-primary p-4 sm:hidden"
        id="download-blue-banner"
      >
        <div
          className="flex flex-col"
          onClick={() => {
            hideBanner(false);
          }}
        >
          {/* HIDE FOR GE15 */}
          {/* <a href={oneLink} target="blank" id="download-app-banner">
            <p className="font-poppins font-bold text-white">
              <Trans id="download-app-banner">It's better on the app</Trans>
            </p>
            <p className="font-poppins text-sm text-white">
              <Trans>Download it here for a more seamless read</Trans>
            </p>
          </a> */}
          <div className="font-poppins text-white">
            <p className="font-bold">
              <Trans>It's better on the app</Trans>
            </p>
            <span className="text-sm">
              <Trans>Get up-to-date info.</Trans>&nbsp;
              <a
                href={oneLink}
                target="blank"
                id="download-app-banner"
                className="font-bold underline"
              >
                <Trans>Download now</Trans>
              </a>
              &nbsp;<Trans>for free!</Trans>
            </span>
          </div>
        </div>
        <XMarkIcon
          className="h-4 w-4 cursor-pointer text-primary3"
          aria-hidden="true"
          onClick={() => {
            hideBanner(true);
          }}
          id="close-banner"
        />
      </div>
    );
  }

  return <div></div>;
};

export default DownloadAppBanner;
